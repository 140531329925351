import React from "react"
import styled, { css } from "styled-components"
import { Grid } from "components/UI/Grid/Grid"
import { HeroText } from "./HeroText"
import { mq } from "styles"
import { $IsSlideshow, $BgColor, HeroItemProps } from "typings/modules"

const containerStyles = ({
  $isSlideshow,
  $bgColor,
}: $IsSlideshow & $BgColor) => css`
  background: ${$bgColor};
  padding: 80px 0 ${$isSlideshow ? "120px" : "80px"};
  ${mq.minWidth("md")} {
    padding: 96px 0 ${$isSlideshow ? "150px" : "96px"};
  }
  ${mq.minWidth("lg")} {
    padding: 120px 0 ${$isSlideshow ? "180px" : "120px"};
  }
`

const Container = styled(Grid)<$IsSlideshow & $BgColor>`
  ${containerStyles};
`

export const HeroTextLayout = ({
  item,
  $isSlideshow,
  $mode,
  $mobileMode,
}: HeroItemProps) => {
  const bgColor = item?.bgColor?.opacityHex
  return (
    <Container
      $isSlideshow={$isSlideshow}
      $bgColor={bgColor}
      base="1 [10] 1"
      md="2 [12] 2"
      lg="6 [12] 6"
    >
      <HeroText
        item={item}
        isText={true}
        $mode={$mode}
        $mobileMode={$mobileMode}
      />
    </Container>
  )
}

export default HeroTextLayout
